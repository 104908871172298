import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axiosCore from 'axios';

Vue.config.productionTip = false

const axios = axiosCore.create({
  // baseURL: 'http://localhost/api/',
  baseURL: 'http://zawody.szkolasport.pl/api/',
  timeout: 20000,
  responseType: "json",
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
  validateStatus: function (status) {
    return status >= 200 && status < 300; // default
  },
});

Vue.prototype.$http = axios;

const app = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
});
store.$app = app;
app.$mount('#app');


router.onReady(() => {
  store.dispatch('user/me')
      .then((user) => {
        if (!user) app.$router.push({ name: 'AuthLoginPage' });
        else if(user && app.$route.name == 'AuthLoginPage') app.$router.push({ name: 'AppDashboard' });
        router.beforeEach((to, from, next) => {
            user = store.getters['user/user'];
            if (to.meta.title) document.title = to.meta.title + " - Dziennik Zawodów";
            if (user && to.name.indexOf('Auth') !== -1) {
              next(false);
            }
            else if (!user && to.name.indexOf('App') !== -1) {
              next(false);
            }
            else{
              next()
            }
          })
      });
})
