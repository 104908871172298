import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import pl from 'vuetify/es5/locale/pl'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { pl },
        current: 'pl',
    },
    customVariables: ['~/assets/variables.scss'],
    icons: {
        iconfont: 'fa',
    },
});
