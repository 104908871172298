const state = {
    user: null,
    loading: false,
    error: [],
    userList: []
};

const actions = {
    async login({ commit }, payload) {
        let self = this;
        commit('START_LOADING');
        await this.$app.$http.post('/auth/login', payload)
            .then(res => {
                commit('SET_USER', res.data.body);
                commit('CLEAR_ERROR');
                self.$app.$router.push({name: "AppDashboard"});
            })
            .catch(err => {
                commit('CLEAR_ERROR');
                commit('SET_ERROR', err.response.data.message)
            })
            .finally(() =>{
                commit("STOP_LOADING");
            });
    },
    async me({commit}){
        return new Promise((resolve) => {
            this.$app.$http.get('/auth/me')
                .then(res => {
                    if(typeof res.data.body !== 'undefined'){
                        commit('SET_USER', res.data.body);
                        resolve(res.data.body)
                    }
                });
        })

    },
    async logout({commit}){
        let self = this;
        await this.$app.$http.post('/auth/logout')
            .then(() => {
                commit('LOGOUT_USER');
                self.$app.$router.push({name: "AuthLoginPage"});
            })
            .catch(err => {
                self.$app.$toast.error(err.response.data.message);
            })
    },
};

const mutations = {
    LOGOUT_USER(state){
       state.user = false;
    },
    SET_USER(state, payload){
        state.user = payload;
    },
    START_LOADING(state){
        state.loading = true;
    },
    STOP_LOADING(state){
        state.loading = false;
    },
    CLEAR_ERROR(state){
        state.error = [];
    },
    SET_ERROR(state, message){
        state.error.push(message);
    }
};

const getters = {
    user: (state) => { return state.user},
    loading: (state) => {return state.loading},
    error: (state) => { return state.error},
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
