import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'AppLayout',
    component: () => import(/* webpackChunkName: "AppLayout" */ '../views/app/layout.vue'),
    children: [
      {
        path: '',
        name: 'AppDashboard',
        component: () => import(/* webpackChunkName: "AppDAshboard" */ '../views/app/index.vue'),
        meta: {
          title: 'Zawody'
        }
      },
      {
        path: 'zawody/:id',
        name: 'AppZawody',
        component: () => import(/* webpackChunkName: "AppZawody" */ '../views/app/zawody.vue'),
        meta: {
          title: 'Zawody edycja'
        }
      },
      {
        path: 'uczniowie',
        name: 'AppUczniowie',
        component: () => import(/* webpackChunkName: "AppUczniowie" */ '../views/app/uczniowie.vue'),
        meta: {
          title: 'Uczniowie'
        }
      },
      {
        path: 'dyscypliny',
        name: 'AppDyscypliny',
        component: () => import(/* webpackChunkName: "AppDyscypliny" */ '../views/app/dyscypliny.vue'),
        meta: {
          title: 'Dyscypliny'
        }
      }
    ]
  },
  {
    path: '/auth/login',
    name: 'AuthLoginPage',
    component: () => import(/* webpackChunkName: "AuthLoginPage" */ '../views/auth/login.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
export default router
