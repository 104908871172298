<template>
        <router-view />
</template>

<script>

export default {
  name: 'App',
  data: () => ({
    //
  }),
  created(){
       this.$vuetify.lang.current = 'pl'
  }
};
</script>

<style>

.letter-space{
    letter-spacing: -0.4px !important;
}
</style>